@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;800&display=swap');

html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    border: 0;
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    background: #F9FAFD;

    @media (max-width: 750px) {
        font-size: 14px;
        line-height: 18px;
    }
}