@import "https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;800&display=swap";
a {
  text-decoration: none;
}

.g_gKjq_notification {
  width: 100%;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 8px;
  padding: 18px 20px;
  display: flex;
}

.g_gKjq_new-notification {
  background: #f7fafd;
}

.g_gKjq_userpic {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  align-self: flex-start;
}

@media (max-width: 750px) {
  .g_gKjq_userpic {
    width: 39px;
    height: 39px;
  }
}

.g_gKjq_notification-content {
  color: #5e6778;
  margin-left: 19px;
}

.g_gKjq_user-name {
  color: #1c202b;
  margin-right: 6px;
  font-weight: 800;
}

@media (hover: hover) {
  .g_gKjq_user-name:hover {
    color: #0a327b;
  }
}

.g_gKjq_post-name {
  color: #5e6778;
  margin-left: 6px;
  font-weight: 800;
}

@media (hover: hover) {
  .g_gKjq_post-name:hover {
    color: #0a327b;
  }
}

.g_gKjq_group-name {
  color: #0a327b;
  margin-left: 6px;
  font-weight: 800;
}

.g_gKjq_new-notification-dot {
  width: 8px;
  height: 8px;
  background: #f65552;
  border-radius: 50%;
  margin-left: 7px;
  display: inline-block;
}

.g_gKjq_time {
  color: #939cad;
  margin-top: 3px;
}

.g_gKjq_message {
  max-width: 566px;
  color: #5e6778;
  border: 1px solid #dde7ee;
  border-radius: 5px;
  margin-top: 13px;
  padding: 17px 20px 20px;
}

.g_gKjq_picture-link {
  margin-left: auto;
}

.g_gKjq_picture {
  width: 45px;
  height: 45px;
  border-radius: 7px;
}

@media (max-width: 750px) {
  .g_gKjq_picture {
    width: 39px;
    height: 39px;
  }
}

.HZ0tta_container {
  width: 730px;
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  display: flex;
  box-shadow: 0 20px 60px #4961a80d;
}

@media (max-width: 750px) {
  .HZ0tta_container {
    width: 100%;
    min-width: 375px;
    padding: 24px 16px 45px;
  }
}

.HZ0tta_header {
  width: 100%;
  margin-bottom: 31px;
  display: flex;
}

@media (max-width: 750px) {
  .HZ0tta_header {
    margin-bottom: 24px;
  }
}

.HZ0tta_title {
  color: #1c202b;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
}

@media (max-width: 750px) {
  .HZ0tta_title {
    font-size: 20px;
    line-height: 25px;
  }
}

.HZ0tta_new-notifications {
  height: 25px;
  color: #fff;
  background: #0a327b;
  border-radius: 6px;
  align-self: center;
  margin-left: 11px;
  padding: 1px 11px 4px;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
}

.HZ0tta_mark-notifications {
  color: #5e6778;
  background: none;
  align-self: center;
  margin-left: auto;
  line-height: 20px;
}

@media (hover: hover) {
  .HZ0tta_mark-notifications:hover {
    color: #0a327b;
    cursor: pointer;
  }
}

.HZ0tta_notifications {
  list-style: none;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
  border: 0;
  margin: 0;
  padding: 0;
}

#root {
  background: #f9fafd;
  justify-content: center;
  align-items: center;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

@media (max-width: 750px) {
  #root {
    font-size: 14px;
    line-height: 18px;
  }
}

/*# sourceMappingURL=index.b34fe59c.css.map */
